.contact{
    position: relative;
    top: 20vh;
}

.contactTitle{
    font-family: 'Sofia Sans Condensed', sans-serif;
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
}

.contacts{
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
}

.contact1, .contact2, .contact3, .contact4{
    height: 400px;
    width: 300px;
    background-color: aliceblue;
    margin: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: all 0.3s linear;
    cursor: pointer;
}

.contact1{
    background-color: #173736;
}

.contact2{
    background-color: #ac9145;
}

.contact3{
    background-color: #786fa6;
}

.contact4{
    background-color: #222f3e;
}

.contact1, .contact2{
    transform: rotate(-5deg);
}

.contact3, .contact4{
    transform: rotate(5deg);
}

.contact1:hover, .contact2:hover, .contact3:hover, .contact4:hover{
    transform: rotate(0deg);
}

.contact1Line, .contact2Line, .contact3Line, .contact4Line{
    height: 370px;
    width: 270px;
    border: 2px solid rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title{
    font-size: 1.7rem;
    position: relative;
    top: 20px;
}

.desc1, .desc2, .desc3, .desc4{
    position: relative;
    top: 50px;
    padding: 25px;
    font-size: 1.5rem;
    color: #ededed;
}

.contact1Line a, .contact2Line a, .contact3Line a, .contact4Line a{
    color: #ededed;
}












@media only screen and (max-width: 900px) {
    .contact{
        top: 10vh;
    }
}


@media only screen and (max-width: 800px) {
    .contacts{
        grid-template-columns: auto;
    }

    .contact2{
        transform: rotate(5deg);
    }

    .contact3{
        transform: rotate(-5deg);
    }

}