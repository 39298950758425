@import url('https://fonts.googleapis.com/css2?family=Kay+Pho+Du:wght@500&display=swap');

.carousel {
  position: relative;
  margin-top: 50px;
  display: flex;
  position: relative;
}

.carousel .arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: solid 1px #40739e;
  color: #40739e;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.carousel .arrow.left {
  left: 50px;
  border-radius: 50%;
}

.carousel .arrow.right {
  right: 50px;
  border-radius: 50%;
}

.carousel .content {
  display: flex;
  align-items: center;
  background: linear-gradient(to bottom, #D5DEE7 0%, #E8EBF2 50%, #E2E7ED 100%), linear-gradient(to bottom, rgba(0,0,0,0.02) 50%, rgba(255,255,255,0.02) 61%, rgba(0,0,0,0.02) 73%), linear-gradient(33deg, rgba(255,255,255,0.20) 0%, rgba(0,0,0,0.20) 100%);
 background-blend-mode: normal,color-burn;
  width: 100vw;
  justify-content: center;
}

.caption{
    position: relative;
    width: 400px;
}

.caption span{
    position: relative;
    top: 10px;
    font-size: 1.2rem;
    font-family: 'Kay Pho Du', serif;
}

.carousel img {
  position: relative;
  height: 300px;
  width: 280px;
  object-fit: cover;
  border-radius: 20px;
  transform: rotate(7deg);
}

.carousel img, .caption{
    margin: 50px;
}

.carousel .content > div {
  margin-left: 20px;
}

.carousel .content h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.carousel .content p {
  font-size: 16px;
}




@media only screen and (max-width: 1000px) {

    .carousel img {
        height: 270px;
        width: 240px;
        object-fit: cover;
        border-radius: 20px;
        transform: rotate(7deg);
      }

    .caption{
        position: relative;
        width: 370px;
    }
    
}



@media only screen and (max-width: 900px) {

    .carousel img {
        height: 250px;
        width: 220px;
        object-fit: cover;
        border-radius: 20px;
        transform: rotate(7deg);
      }

    .caption{
        position: relative;
        width: 370px;
    }
    
    .carousel .arrow.left {
        left: 20px;
        border-radius: 50%;
      }
      
      .carousel .arrow.right {
        right: 20px;
        border-radius: 50%;
      }

      .carousel img, .caption{
        margin: 30px;
    }
    
}


@media only screen and (max-width: 800px) {

    .carousel img {
        height: 230px;
        width: 180px;
        object-fit: cover;
        border-radius: 20px;
        transform: rotate(7deg);
      }

    .caption{
        position: relative;
        width: 320px;
    }
    
    .carousel .arrow.left {
        left: 20px;
        border-radius: 50%;
      }
      
      .carousel .arrow.right {
        right: 20px;
        border-radius: 50%;
      }

      .carousel img, .caption{
        margin: 30px;
    }
    
}


@media only screen and (max-width: 700px) {

    .carousel img {
        position: relative;
        height: 230px;
        width: 180px;
        object-fit: cover;
        border-radius: 20px;
        transform: rotate(7deg);
        left: 30px;
      }

    .caption{
        position: relative;
        width: 280px;
        left: 20px;
    }
    
    .carousel .arrow.left {
        left: 20px;
        border-radius: 50%;
      }
      
      .carousel .arrow.right {
        right: 20px;
        border-radius: 50%;
      }

      .carousel img, .caption{
        margin: 30px;
    }
    
}


@media only screen and (max-width: 600px) {

    .carousel img {
        position: relative;
        min-height: 170px;
        min-width: 170px;
        object-fit: cover;
        border-radius: 20px;
        transform: rotate(7deg);
      }

    .caption{
        position: relative;
        width: 350px;
        left: 10px;
    }

    .caption span{
        font-size: 1rem;
    }
    
    .carousel .arrow.left {
        left: 10px;
        border-radius: 50%;
        top: 10%;
      }
      
      .carousel .arrow.right {
        right: 10px;
        border-radius: 50%;
        top: 90%;
      }

      .carousel img, .caption{
        margin: 30px;
    }

    .carousel .content h2 {
        font-size: 21px;
        margin-bottom: 0px;
      }
    
}



@media only screen and (max-width: 500px) {

    .carousel img {
        position: relative;
        min-height: 170px;
        min-width: 170px;
        object-fit: cover;
        border-radius: 20px;
        transform: rotate(7deg);
        left: 10px;
      }

    .caption{
        position: relative;
        width: 350px;
    }

    .caption span{
        font-size: 1rem;
    }
    
    .carousel .arrow.left {
        left: 10px;
        border-radius: 50%;
        top: 10%;
      }
      
      .carousel .arrow.right {
        right: 10px;
        border-radius: 50%;
        top: 90%;
      }

      .carousel img, .caption{
        margin: 30px;
    }

    .carousel .content h2 {
        font-size: 21px;
        margin-bottom: 0px;
      }
    
}

